<template>
  <div class="trade">
    <div class="trade-top md:py-8 flex items-center">
      <button class="btn mr-10"><i class="icon-arrow mr-3"></i>{{ $t('general.back') }}</button>
      <div class="trade-top__tabs tabs-btn flex">
        <div class="tab-item px-3"
          v-for="tabBtn in tabsBtn"
          :key="tabBtn"
          :class="{'active': tabBtnActive === tabBtn}"
          @click="tabBtnActive = tabBtn"
        >{{ $t(tabBtn) }}</div>
      </div>
      <div class="trade-top__btns flex md:ml-auto">
        <div class="btn-item"
          v-for="btn in btns"
          :key="btn.id"
          :class="{'active': btnActive === btn.id}"
          @click="btnActive = btn.id"
        >{{ $t(btn.name) }}</div>
      </div>
    </div>
    <div class="m:hidden md:px-4 sm:px-4 s:px-5 flex items-center my-4">
      <i class="icon-sound"></i>
      <h5 class="text-gray-light ml-2.5">{{ $t('trade.earn') }}</h5>
      <h5 class="text-blue ml-2">{{ $t("trade.check_details") }}</h5>
    </div>

    <div class="trade-body flex pb-5" v-if="optionByUuid || btnActive !== 0">
      <div class="trade-card card" v-for="(trade, key) in listOptions" :key="key">
        <div class="card-header" v-if="btnActive === 0">
          <div class="card-header__title">{{ formatCrypto(trade.currency_type) }} {{ formatNumber(optionByUuid[trade.option_project_uuid].current_position_price, 2) }}</div>
          <i class="icon-chart"></i>
          <i class="icon-arrow ml-auto rotate-180 sm:hidden block"></i>
        </div>
        <div class="card-body flex">
          <div class="s:w-1/3 w-1/2">
            <span class="bg-green count">{{ $t("trade.quantity") }}</span>
            <p class="md:mt-2 mt-1">{{ $t("trade.quantity") }} {{ trade.crypto_amount }} 个</p>
            <p class="md:mt-2 mt-1">Deposit {{ trade.deposit }}</p>
          </div>
          <div class="s:w-1/3 w-1/4">
            <p class="md:mb-2 mb-1">{{ $t('trade.buying_price') }} {{ trade.position_price }}</p>
            <p>{{ $t('trade.stop_profit') }} {{ trade.stop_profit_price }}</p>
            <p>{{ $t('trade.stop_loss') }} {{ trade.stop_loss_price }}</p>
          </div>
          <div class="s:w-1/3 w-1/4" v-if="btnActive === 0">
            <p class="text-red text-right md:mb-2 mb-1">{{ formatNumber(optionByUuid[trade.option_project_uuid].profit, 3) }}</p>
            <p class="text-red text-right">{{ formatNumber(optionByUuid[trade.option_project_uuid].profit / trade.deposit, 3) }}%</p>
          </div>
        </div>
        <div class="card-footer flex" v-if="btnActive === 0">
          <button class="btn ml-auto max-h-8">{{ $t('trade.take_profit_and_stop_loss') }}</button>
          <button class="btn ml-2.5 max-h-8" @click="closeOption(trade.option_project_uuid)">{{ $t('trade.close_position') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Trade',
  data: () => ({
    tabsBtn: ['trade.simulated_trading', 'trade.simulate_position'],
    tabBtnActive: 'trade.simulated_trading',
    btns: [
      { name: 'trade.position', id: 0 },
      { name: 'trade.settlement', id: 1 },
      { name: 'trade.cancellation', id: 2 }
    ],
    btnActive: 0
  }),
  async created () {
    await this.$store.dispatch('list_options')
  },
  computed: {
    listOptions () {
      const tmp = this.$store.state.trade.listOptionsData
      if (this.btnActive === 0) return tmp.filter(item => !item.closed_on)
      else if (this.btnActive === 1) return tmp.filter(item => item.system_force_closed)
      else return tmp.filter(item => item.system_force_closed === false)
    },
    optionByUuid () {
      const tmp = this.$store.state.trade.tradeSubscribers
      return Object.keys(tmp).length > 0 ? tmp : false
    }
  },
  methods: {
    async closeOption (uuidOption) {
      await this.$store.dispatch('close_option', uuidOption)
      await this.$store.dispatch('list_options')
    },
    formatNumber (value, nums = 3) {
      return Number(value.toFixed(nums))
    },
    formatCrypto (str) {
      return `${str.split('USDT')[0]}/USDT`
    }
  },
  beforeDestroy () {
    this.$store.dispatch('ws_options_list_unsubscribe')
  }
}
</script>
